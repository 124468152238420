// -- copyright
// OpenProject is a project management system.
// Copyright (C) 2012-2018 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.rdoc for more details.

/**
 * This file will be symlinked into the angular compiler project
 * at the OpenProject core frontend folder.
 *
 * From here, you can work with anything angular related just in the core.
 * When developing, we recommend you run the script `./bin/setup_dev` in the OpenProject
 * core first, and then develop the frontend in the frontend/src/app/modules/plugins/linked/ folder.
 *
 * This will allow your IDE to pick up the angular project and provide import assistance and so on.
 */

import {
  APP_INITIALIZER,
  Injector,
  NgModule,
} from '@angular/core';
import './global_scripts';
import { UIRouterModule } from '@uirouter/angular';
import { HookService } from 'core-app/features/plugins/hook-service';
import { CommonModule } from '@angular/common';
import { ReturnOrderComponent } from 'core-app/features/plugins/linked/openproject-qr_pdf_print/qr-pdf-print-component/qr-pdf-print.component';
import { WorkPackageTabsService } from 'core-app/features/work-packages/components/wp-tabs/services/wp-tabs/wp-tabs.service';
// import { OpenprojectCommonModule } from "core-app/modules/common/openproject-common.module";
import { NgSelectModule } from '@ng-select/ng-select';
import { OpenprojectEditorModule } from 'core-app/shared/components/editor/openproject-editor.module';
import { FormsModule } from '@angular/forms';
export function initializeReturnOrderTabIntegrationPlugin(injector:Injector) {
  const wpTabService = injector.get(WorkPackageTabsService);
  wpTabService.register({
    component: ReturnOrderComponent,
    name: I18n.t('js.qr_pdf_print.work_packages.tab_name'),
    id: 'qr_pdf_print',
    displayable: (workPackage) => !!workPackage.qr_pdf_print,
  });
}

export function initializeReturnOrderPlugin(injector:Injector) {
  return () => {
    const hookService = injector.get(HookService);

    hookService.register('openProjectAngularBootstrap', () => {
      return [];
    });
  };
}

@NgModule({
  imports: [
    CommonModule,
    // OpenprojectCommonModule,
    NgSelectModule,
    OpenprojectEditorModule,
    FormsModule
  ],
  providers: [
    // This initializer gets called when the Angular frontend is being loaded by the core
    // use it to hook up global listeners or bootstrap components
    {
      provide: APP_INITIALIZER,
      useFactory: initializeReturnOrderPlugin,
      deps: [Injector],
      multi: true,
    },
  ],
  declarations: [
    // Declare the component for angular to use
    ReturnOrderComponent
  ],
})
export class PluginModule {
  constructor(injector:Injector) {
    initializeReturnOrderTabIntegrationPlugin(injector);
  }
}
