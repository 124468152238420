import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReturnOrderService {

  url:string;

  constructor(private httpClient: HttpClient) {}

  // getByWorkPackage(project_id: any, wp_id: any) {
  //   return this.httpClient.get('/qr_pdf_prints_plugin/projects/'+project_id+'/qr_pdf_prints/get_by_work_package?work_package_id='+wp_id);
  // }
  //
  // update(project_id: any, data: any) {
  //   return this.httpClient.put('/qr_pdf_prints_plugin/projects/'+project_id+'/qr_pdf_prints/qr_pdf_prints/update_return_order', data);
  // }
}
