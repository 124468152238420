import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder
} from '@angular/forms';
import {
  ICKEditorType
} from "core-app/shared/components/editor/components/ckeditor/ckeditor-setup.service";
import {
  ICKEditorContext,
  ICKEditorInstance,
} from 'core-app/shared/components/editor/components/ckeditor/ckeditor.types';
import {I18nService} from "core-app/core/i18n/i18n.service";
import {HalResource} from 'core-app/features/hal/resources/hal-resource';
import { WorkPackageResource } from 'core-app/features/hal/resources/work-package-resource';
import { TabComponent } from "core-app/features/work-packages/components/wp-tabs/components/wp-tab-wrapper/tab";
import { ReturnOrderService } from 'core-app/features/plugins/linked/openproject-qr_pdf_print/services/qr-pdf-print.service';
import { ToastService } from 'core-app/shared/components/toaster/toast.service';


@Component({
  selector: 'qr-pdf-print-component',
  templateUrl: './qr-pdf-print.component.html',
  styleUrls: ['./qr-pdf-print.component.sass']
})

export class ReturnOrderComponent implements TabComponent, OnInit {
  @Input() public workPackage:WorkPackageResource;
  @ViewChild('input') input: ElementRef;


  public initialContent:string;
  public context:ICKEditorContext;
  public macros:boolean;

  return_order: any = {};
  editorInstance:ICKEditorInstance;
  errorMessage:string;

  text = {
    'print_feature_description': this.I18n.t('js.qr_pdf_print.print_feature_description'),
    'print': this.I18n.t('js.qr_pdf_print.print'),
    'label_create_success': this.I18n.t('js.qr_pdf_print.create_success'),
    'label_update_success': this.I18n.t('js.qr_pdf_print.update_success'),
    'label_header': this.I18n.t('js.qr_pdf_print.work_packages.tab_name')
  }

  constructor(
    readonly I18n:I18nService,
    readonly elementRef:ElementRef,
    private formBuilder: FormBuilder,
    private returnOrderService:ReturnOrderService,
    readonly toastService:ToastService
  ) { }

  ngOnInit() {

  }

  public setup(editor:ICKEditorInstance) {

  }

  onSubmit($event:any, action: any) {
    this.getReturnOrderPdf();
  }


  private getReturnOrderPdf() {
    let pdf_url = '/qr_pdf_prints_plugin/projects/'+this.workPackage.project.id+'/qr_pdf_prints/generate_pdf.pdf?work_package_id='+this.workPackage.id;
    window.open(pdf_url, '_self');
  }
}
